$small-phone-height: 620px;
$phone: 640px;
$tablet: 768px;
$desktop: 1024px;

.register-image {
  width: 100%;
  max-width: 1200px;

  @media screen and (max-width: $phone) {
    transform: scale(1.6);
    -ms-transform: scale(1.6);
    -moz-transform: scale(1.6);
    -webkit-transform: scale(1.6);
    -o-transform: scale(1.6);
    margin-left: 50px;
    margin-top: 50px;
  }
}

.register-background-img-wrapper{
  padding-top: 5px;
  display: inline-block;
  overflow: hidden;
  border-radius: 5px;
}