.full-width-button{
    padding: 8px 32px;
    width: 100%;
    cursor: pointer;
    border: none;
    border-radius: 20px;
    display: flex;
    gap: 8px;
    transition: .2s;
    background-color: $pdi-action-blue;
    color: #fff;
    font-size: .88rem;
    letter-spacing: .34px;
    text-align: center;
    white-space: nowrap;

    span{
        flex-grow: 1;
    }
}

.primary-button{
    padding: 8px 32px;
    width: auto;
    cursor: pointer;
    border: none;
    border-radius: 20px;
    display: flex;
    gap: 8px;
    transition: .2s;
    background-color: $pdi-action-blue;
    color: #fff;
    font-size: .88rem;
    letter-spacing: .34px;
    text-align: center;
    white-space: nowrap;

    span{
        flex-grow: 1;
    }
}