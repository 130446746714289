$phone: 640px;
$tablet: 768px;
$desktop: 1024px;

.nft-list-container {
  list-style-type: none;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start; // if you want to fill rows left to right
}

.nft-container {
  padding: 10px;
  display: inline-block;

  @media screen and (max-width: $tablet) {
    width: 50%;
  }

  @media screen and (min-width: $tablet) and (max-width: $desktop) {
    width: 33%;
  }
}

.nft-name {
  margin: 0px;
  margin-right: 10px;
  display: inline-block;
}

.nft-address {
  display: inline-block;
}

.thumb-image {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  margin: auto;
}

.nft-image-detail {
  max-height: 300px;
  max-width: 300px;
  width: 70vw;
  height: 70vw;
  border-radius: 50%;
  border: 5px solid rgb(216, 226, 240);
}
