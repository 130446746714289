@import "~@pdi/platform-common-styles/styles/scss/partials/colors";
@import "./styles/Button.scss";
@import "./styles/Minter.scss";
@import "./styles/Login.scss";
@import "./styles/Profile.scss";
@import "./styles/Register.scss";
@import "./styles/Variables.scss";
@import "./styles/Notification.scss";
@import "./styles/NftList.scss";

/* latin-ext */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriCZ2IHTWEBlwu8Q.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriCZOIHTWEBlw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriASitCBamC3YU-CnE6Q.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriASitCBimC3YU-Ck.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

$phone: 640px;
$tablet: 768px;
$desktop: 1024px;

.App {
  display: flex;
  text-align: center;
  background-color: rgb(216, 226, 240);
}

html,
body,
#reactapp,
.App {
  background-color: rgb(216, 226, 240) !important;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#root {
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
}

.our-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border-radius: 5px;
  background-color: rgb(216, 226, 240) !important;
  margin: 0 auto 0;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 5px;
  text-align: "center";
  border: "0px !important";
  max-width: 400px;
  height: 100%;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.landing-page-logo {
  background-color: black;
  /* margin-top: 10rem; */
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.landing-page-logo-image {
  border-radius: 50%;
  max-height: 200px;
}

.landing-page-logo-container {
  background-color: black;
}

body {
  margin: 0;
  font-family: 'DM Sans' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'DM Sans', monospace !important;
}

.error {
  color: red;
}

.posts {
  list-style: none;
  margin: 0px auto;
  text-align: center;
}

.post {
  background-color: white;
  border: 1px solid $pdi-blue;
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px;
  width: fit-content;
  float: left;
  margin-right: 1em;
  max-width: 500px;
}

.nav-bar {
  // font-size: 2rem;
  // height: 2rem;
  padding: 5px;
}

.icon-container {
  float: right;
}

.profile-icon {
  color: #06409e;
  margin-left: 5px;
  margin-right: 5px;

  &-tight {
    color: #06409e;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.add-contract-button-container {
  height: 30px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.modal {
  position: "absolute" as "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
  background-color: "background.paper";
  border-radius: "5px";
  box-shadow: 24;
  /* p: 4; */
}

.page-title {
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  color: $pdi-blue;
  font-size: 1em;
  font-weight: 600;
}

.page-action {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  font-size: 1.8rem;
  color: $pdi-blue;
  font-weight: 600;
}

.page-container {
  background-color: #d8e2f0;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-align: center;
}

.page-description {
  font-size: 1em;
  text-align: center;
  margin-bottom: 30px;
}

.input-field {
  align-items: center;
  border: 0.07rem solid #d2d2d7;
  border-radius: 50px !important;
  height: 2.19rem;
  justify-content: space-between;
  margin-top: 0.13rem;
  max-height: 2.19rem;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0 0.25rem 0.75rem rgb(6 64 158 / 12%);
  width: 100%;
}

.our-card-body {
  padding: 20px;
}

.nft-detail-title {
  margin-top: 20px;
  color: $pdi-blue;
}

.nft-id-label {
  font-weight: bold;
}

.spinner-border {
  height: 1rem !important;
  width: 1rem !important;
  margin-right: 10px !important;
}

.full-width-button-without-span {
  padding: 8px 32px;
  width: 100%;
  cursor: pointer;
  border: none;
  border-radius: 20px;
  display: flex;
  gap: 8px;
  transition: 0.2s;
  background-color: $pdi-action-blue;
  color: #fff;
  font-size: 0.88rem;
  letter-spacing: 0.34px;
  text-align: center;
  white-space: nowrap;
}

.simple-separator {
  background-color: $pdi-medium-gray;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pdi-input-label {
  display: block;
  text-align: left;
  font: normal normal normal 14px/21px 'DM Sans' !important;
  letter-spacing: 0.34px;
  color: $pdi-soft-black;
  text-transform: capitalize;
  opacity: 1;
}

.large-spinner {
  height: 4rem !important;
  width: 4rem !important;
}

.font-size-0-8 {
  font-size: 0.8rem;
}

.pb20 {
  margin-bottom: 20px;
}

.pdi-top-logo {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.alert {
  padding: 5px !important;
  text-align: center;
}

.full-width-button {
  font-weight: 600 !important;
}

.h100 {
  height: 100%;
}

.limitMaxFormHeigh {
  max-height: 844px;
}


.hills-background-image {
  width: 100%;
  z-index: 4;
  max-width: 1500px;

  @media screen and (max-width: $phone) {
    transform: scale(1.4);
    -ms-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -webkit-transform: scale(1.4);
    -o-transform: scale(1.4);

    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.modal-header {
  border: none !important;
}

.modal-footer {
  border: none !important;
}

.centered-container {
  width: fit-content;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#video-container {
  line-height: 0;
  background-color: black;
}

#video-container .scan-region-highlight-svg,
#video-container .code-outline-highlight {
  stroke: #00B5AD !important;
}

#video-container.scan-region-highlight {
  border-radius: 30px;
  outline: rgba(0, 0, 0, .25) solid 50vmax;
}

#video-container.scan-region-highlight-svg {
  display: none;
}

#video-container .code-outline-highlight {
  stroke: rgba(255, 255, 255, .5) !important;
  stroke-width: 15 !important;
  stroke-dasharray: none !important;
}

#flash-toggle {
  display: none;
}


.pdi-dropdown {
  border: 0;
  width: 100%;
  max-width: none;
  margin: 0;
  padding: 0.375rem 0.75rem;
  // text-align: left;
  background-color: $pdi-white;
  border: 1px solid $pdi-medium-gray;

  border-radius: 20px;
  opacity: 1;
  text-align: left;
  display: flex;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

.pdi-dropdown-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 8px 12px;
  opacity: 1;

  &-label-conatiner {
    text-align: left;
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0.34px;
    color: $pdi-soft-black;
  }

  &:hover {
    background: $pdi-light-gray 0% 0% no-repeat padding-box;
  }

  &:not(.disabled):focus-within {
    background: #1973ea0d 0% 0% no-repeat padding-box;
    border: 1px solid $pdi-blue;
    border-radius: 8px;
    opacity: 1;

    .pdi-dropdown-option-label-container {
      text-align: left;
      font: normal normal normal 14px/21px Poppins;
      letter-spacing: 0.34px;
      color: $pdi-blue;
    }
  }

  &.selected {
    background: #1973ea0d 0% 0% no-repeat padding-box;

    .pdi-dropdown-option-label-container {
      font-weight: bold;
      color: $pdi-primary;

      &>* {
        font-weight: inherit;
      }
    }
  }

  &.disabled {
    color: $pdi-medium-gray !important;

    .pdi-dropdown-option-label-container {
      font: normal normal normal 14px/21px Poppins;
      letter-spacing: 0.34px;
      color: $pdi-medium-gray !important;
    }
  }
}

.MuiButtonBase-root {
  background-color: white !important;
  border-radius: 5px !important;
  margin-left: 5px !important;
  color: #100E3A !important;
  font-weight: bold !important;
  min-height: 2.2rem !important;
  height: 2.2rem;

  &.Mui-selected {
    background-color: #99007E !important;
    color: #fff !important;
    border-radius: 5px !important;
    margin-left: 5px !important;
    font-weight: bold !important;
  }
}

.MuiTabs-root {
  display: inline-block !important;
  margin-left: 15px;
}

button:disabled {
  border: 1px solid #E8EEF3 !important;
  color: #031D4780 !important;
  background-color: #E8EEF3 !important;
}