.title-codes {
  color: $pdi-blue;
  text-align: left;
  font-size: 0.9rem;
  margin-bottom: 20px;
}

.titled-wallet-address {
  text-align: left;
  padding-top: 20px;
  font-size: 0.9rem;
  font-weight: 500;
}

.show-advanced-button {
  margin-top: 20px;
}

.code-info {
    padding-top: 10px;
    text-align: left;
}
