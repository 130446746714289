$pdi-white: #ffffff;
$pdi-ghost-white: #f9f9fb;

$pdi-blue: #06409e;
$pdi-action-blue: #0d6aff;
$pdi-primary: $pdi-blue;
$pdi-soft-blue: #1973EA0D;

$pdi-light-blue: #0d6aff0d;
$pdi-light-action-blue: $pdi-light-blue;
$pdi-tint-blue: #f2f7ff;
$pdi-shadow-T8: #0d6aff14;
$pdi-shadow-T12: #0d6aff1f;

$pdi-teal: #00b5ad;
$pdi-light-teal: #00b5ad0d;

$pdi-success: #00893f;
$pdi-tint-green: #f2fbfb;

$pdi-red: #e60d2e;
$pdi-alert: $pdi-red;
$pdi-light-red: #e60d2e0d;
$pdi-tint-red: #fef2f4;

$pdi-yellow: #fbbd08;
$pdi-status: $pdi-yellow;
$pdi-light-yellow: #fbbd080d;
$pdi-tint-yellow: #fff8e5;

$pdi-orange: #f2711c;
$pdi-warning: $pdi-orange;

$pdi-medium-gray: #d2d2d7;
$pdi-medium-grey: $pdi-medium-gray;

$pdi-grey: $pdi-medium-grey;
$pdi-gray: $pdi-grey;
$pdi-dark-gray: #717171;
$pdi-soft-grey-dividers: #E2E2E2;

$pdi-soft-black: #333333;
$pdi-black: #000000;

$pdi-light-gray: #f7f7fb;
$pdi-light-grey: $pdi-light-gray;

// Dimensions
$border-radius: 8px;

$gap-small:10px;
$gap-medium:20px;

:export {
  // Colors
  pdiBlue: $pdi-blue;
  pdiActionBlue: $pdi-action-blue;
  pdiSoftBlack: $pdi-soft-black;
  pdiPrimary: $pdi-primary;
  pdiTeal: $pdi-teal;
  pdiSuccess: $pdi-success;
  pdiWhite: $pdi-white;
  pdiRed: $pdi-red;
  pdiAlert: $pdi-alert;
  pdiOrange: $pdi-orange;
  pdiWarning: $pdi-warning;
  pdiMediumGray: $pdi-medium-gray;
  pdiMediumGrey: $pdi-medium-grey;
  pdiYellow: $pdi-yellow;
  pdiStatus: $pdi-status;
  pdiGrey: $pdi-grey;
  pdiGray: $pdi-grey;
  pdiBlack: $pdi-black;
  pdiLightYellow: $pdi-light-yellow;
  pdiLightBlue: $pdi-light-blue;
  pdiLightRed: $pdi-light-red;
  pdiLightTeal: $pdi-light-teal;
  pdiLightGray: $pdi-light-gray;
  pdiLightGrey: $pdi-light-grey;
  pdiLightActionBlue: $pdi-light-action-blue;
  pdiTintBlue: $pdi-tint-blue;
  pdiShadowT8: $pdi-shadow-T8;
  pdiShadowT12: $pdi-shadow-T12;
  pdiGhostWhite: $pdi-ghost-white;

  // Dimensions
  borderRadius: $border-radius;
  
  gap-small:$gap-small;
  gap-medium:$gap-medium;
}