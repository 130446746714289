.horizontal-separator {
  text-align: center;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;

  & > span {
    font-size: 14px;
    font-weight: 700;
    color: $pdi-dark-gray;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    background-color: $pdi-white;
    display: inline-block;
    padding: 0 4px;
  }
  &::before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    top: calc(50% - 1px);
    background-color: $pdi-medium-gray;
  }
}

.pdi-login {
  margin: 10px;
  display: grid;
  text-align: center;
  justify-content: center;

  &-card {
    width: 350px;
    display: flex;
    justify-content: center;
    justify-self: center;
    box-shadow: 0px 12px 24px $pdi-shadow-T12 !important;

    &-body {
      margin-bottom: 20px !important;
      margin-top: 20px !important;
      flex-direction: column;
    }
  }

  &-logo {
    justify-content: center;
    align-items: center;
    display: block;
  }

  &-app-name {
    display: block;
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    letter-spacing: 0px;
    color: $pdi-soft-black;
    opacity: 1;
    margin: 8px 0px 4px 0px;
  }

  &-description {
    display: block;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0px;
    color: $pdi-soft-black;
    opacity: 1;
    margin: 4px 0px 16px 0px;
  }

  &-label {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    color: $pdi-soft-black;
    opacity: 1;
  }

  &-error {
    display: flex;
    justify-content: stretch;
    flex-direction: row;
    border-radius: 8px;
    border-left: 8px solid $pdi-red;
    background-color: $pdi-light-red;
    align-items: center;

    &-icon-container {
      padding: 15px 5px 15px 15px;
    }

    &-icon {
      color: $pdi-red;
      font-size: 25px;
    }

    &-message {
      padding: 10px 20px 10px 5px;
      text-align: left;
      font: normal normal normal 13px/18px Poppins;
      letter-spacing: 0.31px;
      color: $pdi-soft-black;
      opacity: 1;
    }
  }
}

.pdi-login-label {
  text-align: center;
  margin-bottom: 20px;
  color: $pdi-blue;
  font-weight: 600;
}

.pdi-login-app-name {
  text-align: center;
  color: $pdi-blue;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 20px;
}

.pdi-login-header-container {
  background-color: rgb(216, 226, 240) !important;
}

.pdi-form-body-container {
  margin: 20px;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  background-color: white;
}

.background-img-wrapper {
  padding-top: 5px;
  display: inline-block;
  overflow: hidden;
  border-radius: 5px;
  position: absolute;
  bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}