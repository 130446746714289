.info {
  & .pdi-page-notification__body {
    background-color: $pdi-tint-blue;

    & .pdi-page-notification__body__content .pdi-page-notification__body__content-icon {
      color: $pdi-blue;
    }

    & .pdi-page-notification__body__accent-bar {
      background-color: $pdi-blue;
    }
  }
}

.warning {
  & .pdi-page-notification__body {
    background-color: $pdi-tint-yellow;

    & .pdi-page-notification__body__content .pdi-page-notification__body__content-icon {
      color: $pdi-yellow;
    }

    & .pdi-page-notification__body__accent-bar {
      background-color: $pdi-yellow;
    }
  }
}

.alert {
  & .pdi-page-notification__body {
    background-color: $pdi-tint-red;

    & .pdi-page-notification__body__content .pdi-page-notification__body__content-icon {
      color: $pdi-red;
    }

    & .pdi-page-notification__body__accent-bar {
      background-color: $pdi-red;
    }
  }
}

.success {
  & .pdi-page-notification__body {
    background-color: $pdi-tint-green;

    & .pdi-page-notification__body__content .pdi-page-notification__body__content-icon {
      color: $pdi-success;
    }

    & .pdi-page-notification__body__accent-bar {
      background-color: $pdi-success;
    }
  }
}

.pdi-page-notification__body {
  overflow: hidden;
  border-radius: $gap-small;
  width: 100%;
  display: flex;
  align-items: center;

  & .pdi-page-notification__body__spacer {
    flex: 1;
  }


  & .pdi-page-notification__body__action {
    color: $pdi-soft-black;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: underline;
  }

  & .pdi-page-notification__body__accent-bar {
    min-width: 8px;
    align-self: stretch;
  }

  & .pdi-page-notification__body__content {
    display: flex;
    align-items: center;
    gap: $gap-medium;

    & .pdi-page-notification__body__content-icon {
      font-size: 2em;
    }

    & .pdi-page-notification__body__content-text {
      color: $pdi-soft-black;
      text-align: left;
    }

    padding: $gap-medium;
  }

  & .pdi-page-notification__body__close {
    margin: 0 $gap-medium;
    cursor: pointer;
    color: $pdi-dark-gray;
  }
}